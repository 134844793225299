var _a;
import { LAYOUT_CARD_SMALL, LAYOUT_CARD_MEDIUM, LAYOUT_CARD_LARGE, LAYOUT_CARD_PROFILE, LAYOUT_FULL_POST, LAYOUT_MOBILE, LAYOUT_SIDE_BY_SIDE, LAYOUT_GRID, LAYOUT_TEXT_ON_IMAGE_SMALL, LAYOUT_TEXT_ON_IMAGE_MEDIUM, LAYOUT_ONE_COLUMN_MOBILE, LAYOUT_SIDE_BY_SIDE_MOBILE, LAYOUT_TEXT_ON_IMAGE_MOBILE, LAYOUT_PG_TEXT_ON_IMAGE_SMALL, LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM, LAYOUT_PG_TEXT_ON_IMAGE_LARGE, LAYOUT_PG_CARD_MEDIUM, LAYOUT_PG_GRID_INTERMEDIATE, LAYOUT_PG_GRID_LARGE, LAYOUT_PG_SIDE_BY_SIDE, LAYOUT_PG_SIDE_BY_SIDE_RIGHT, LAYOUT_PG_GRID, LAYOUT_PG_ONE_COLUMN, LAYOUT_SLIDER, LAYOUT_LIST, LAYOUT_LIST_MEDIUM, LAYOUT_LIST_LARGE, } from './layout-types';
// Posts
export var POST_FONT_SIZES = (_a = {},
    _a[LAYOUT_CARD_SMALL] = {
        title: {
            start: 22,
            min: 16,
            max: 22,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_CARD_MEDIUM] = {
        title: {
            start: 26,
            min: 18,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_CARD_LARGE] = {
        title: {
            start: 28,
            min: 20,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_CARD_PROFILE] = {
        title: {
            start: 26,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_FULL_POST] = {
        title: {
            start: 40,
        },
        description: {
            start: 18,
        },
        metadata: {
            start: 14,
        },
    },
    _a[LAYOUT_MOBILE] = {
        title: {
            start: 22,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_SIDE_BY_SIDE] = {
        title: {
            start: 28,
            min: 20,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_GRID] = {
        title: {
            start: 22,
            min: 16,
            max: 22,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_TEXT_ON_IMAGE_SMALL] = {
        title: {
            start: 22,
            min: 16,
            max: 22,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_TEXT_ON_IMAGE_MEDIUM] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_ONE_COLUMN_MOBILE] = {
        title: {
            start: 22,
            min: 12,
            max: 30,
        },
        description: {
            start: 14,
            min: 12,
            max: 18,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_SIDE_BY_SIDE_MOBILE] = {
        title: {
            start: 16,
            min: 12,
            max: 30,
        },
        description: {
            start: 14,
            min: 12,
            max: 18,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_TEXT_ON_IMAGE_MOBILE] = {
        title: {
            start: 22,
            min: 12,
            max: 30,
        },
        description: {
            start: 16,
            min: 12,
            max: 18,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_CARD_MEDIUM] = {
        title: {
            start: 26,
            min: 18,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_PG_SIDE_BY_SIDE] = {
        title: {
            start: 28,
            min: 20,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = {
        title: {
            start: 28,
            min: 20,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_PG_GRID] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_GRID_INTERMEDIATE] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_GRID_LARGE] = {
        title: {
            start: 36,
            min: 26,
            max: 48,
        },
        description: {
            start: 16,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_PG_ONE_COLUMN] = {
        title: {
            start: 28,
            min: 20,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_SLIDER] = {
        title: {
            start: 28,
            min: 20,
            max: 28,
        },
        description: {
            start: 16,
            min: 12,
            max: 16,
        },
        metadata: {
            start: 12,
            min: 10,
            max: 14,
        },
    },
    _a[LAYOUT_LIST] = {
        title: {
            start: 14,
        },
        description: {
            start: 0,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_LIST_MEDIUM] = {
        title: {
            start: 14,
        },
        description: {
            start: 0,
        },
        metadata: {
            start: 12,
        },
    },
    _a[LAYOUT_LIST_LARGE] = {
        title: {
            start: 14,
        },
        description: {
            start: 0,
        },
        metadata: {
            start: 12,
        },
    },
    _a);
export var DEFAULT_TOP_NAVIGATION_FONT_SIZE = 14;
export var getDefaultFontSize = function (layoutType, contentType) { return POST_FONT_SIZES[layoutType][contentType].start; };
export var getMaxFontSize = function (layoutType, contentType) { return POST_FONT_SIZES[layoutType][contentType].max; };
export var getMinFontSize = function (layoutType, contentType) { return POST_FONT_SIZES[layoutType][contentType].min; };
